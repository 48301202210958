import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ChatList from 'components/Inbox/ChatList';
import Inbox from 'components/Inbox/Inbox';
import { ChatContext } from 'context/ChatContext';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

export const ChatInbox: React.FC = () => {
    const isMobile = useMediaQuery('(max-width:768px)');
    const { roomId } = useParams();
    const { requestToJoinRoom } = useContext(ChatContext);

    useEffect(() => {
        if (roomId) {
            requestToJoinRoom(roomId);
        }
        return;
    }, [roomId, requestToJoinRoom]);

    return (
        <>
            <Helmet>
                <title>Inbox</title>
            </Helmet>
            <Grid className="layout-header">
                <Box
                    className="main-outer-box"
                    sx={{
                        boxShadow: 2,
                        width: 1,
                    }}
                >
                    <Grid container>
                        {isMobile ? (
                            <>
                                <Grid item xs={12}>
                                    {roomId ? <Inbox /> : <ChatList />}
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={4}>
                                    <ChatList />
                                </Grid>
                                <Grid item xs={8}>
                                    {
                                        roomId
                                            ? <Inbox />
                                            : <p className="no-convo-selected">No conversation selected.</p>
                                    }
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </Grid>
        </>
    );
};
