import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Drawer, IconButton, Menu, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import 'components/Inbox/style.scss';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import LeadActions from 'components/Inbox/LeadActions';
import MDButton from 'components/themed/MDButton';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetChatRoomDetailsQuery } from 'redux/services/spotdif/chatToken';
import { selectChatRoomById } from 'redux/slices/chat';
import { useTypedSelector } from 'redux/store';
import ChatRoomDetails from './ChatRoomDetails';
import RateLead from './RateLead';

const options = ['Jump to Lead', 'Close this lead'];

const MENU_ITEM_HEIGHT = 48;

const ChatHeaderMobile = () => {
    const navigate = useNavigate();

    const { roomId } = useParams();
    const currentRoom = useTypedSelector((state) => selectChatRoomById(state, roomId));

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isChatroomDetailsOpen, setIsChatRoomDetailsOpen] = React.useState(false);

    const { leadId, isHydrated, isChatAllowed, isLoading, isError } = useGetChatRoomDetailsQuery(roomId, {
        selectFromResult: ({ data, isLoading, isError }) => {
            const hydrationStatus = data?.data?.isHydrated ?? false;
            return {
                leadId: data?.data?._id,
                isHydrated: hydrationStatus,
                isError: isError,
                isLoading: isLoading,
                isChatAllowed: (isLoading || !isError) && hydrationStatus,
            };
        },
    });

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateChatRoomDetailsDrawer = useCallback((isOpen: boolean) => () => {
        setIsChatRoomDetailsOpen(isOpen);
    }, []);

    const explainLeadHydration = useCallback(() => {
        enqueueSnackbar(`The lead is not available for this account yet, please contact support.`, {
            variant: 'error',
            key: 'lead-hydration',
        });
    }, []);

    return (
        <>
            <Box
                className="header-box header-box-mobile"
                sx={{
                    boxShadow: 2,
                    width: 1,
                }}
            >
                <Grid container className="chat-header chat-header-mobile" xs={12} md={12} item>
                    <Grid xs={1} onClick={() => navigate(-1)} item>
                        <ArrowBackIcon className="inbox-arrow-back" />
                    </Grid>
                    <Grid item xs={6} md={6} className="chat-user-info chat-user-info-mobile">
                        <Typography className="user-name" variant="body1">
                            {currentRoom?.formattedTitle}
                        </Typography>
                        <Typography variant="body2">#{currentRoom?.leadId}</Typography>
                    </Grid>
                    <AuthorisedUsage module={MODULE.CHAT_INBOX} permission={PERMISSIONS.UPDATE}>
                        {isChatAllowed &&
                            <MDButton sx={{ textTransform: 'capitalize' }} className="lead-details-btn"
                                      onClick={updateChatRoomDetailsDrawer(true)}>
                                Lead Details
                            </MDButton>}
                        <Drawer
                            anchor="right"
                            open={isChatroomDetailsOpen}
                            onClose={updateChatRoomDetailsDrawer(false)}
                            PaperProps={{
                                style: {
                                    minWidth: 375, // Set your desired minimum width here
                                    padding: '0.75rem',
                                },
                            }}
                        >
                            <ChatRoomDetails chatRoomId={roomId}
                                             closeDetails={() => updateChatRoomDetailsDrawer(false)} />
                        </Drawer>
                    </AuthorisedUsage>

                    <AuthorisedUsage module={MODULE.CHAT_INBOX} permission={PERMISSIONS.UPDATE}>

                        <Grid item xs={1} md={1} className="menu-option">
                            {isChatAllowed &&
                                (<IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>)
                            }
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: MENU_ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option} onClick={handleClose}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </AuthorisedUsage>
                </Grid>
                <Grid item xs={12} md={12} className="rating-cnt d-flex rate-lead-action-container">
                    <AuthorisedUsage module={MODULE.CHAT_INBOX} permission={PERMISSIONS.UPDATE}>
                        {isChatAllowed && <RateLead chatRoomId={roomId} />}
                    </AuthorisedUsage>
                    <AuthorisedUsage module={MODULE.CHAT_INBOX} permission={PERMISSIONS.UPDATE}>
                        {isChatAllowed && <LeadActions chatRoomId={roomId} />}
                    </AuthorisedUsage>
                    {
                        !isChatAllowed && (
                            <MDButton sx={{ textTransform: 'capitalize' }}
                                      onClick={explainLeadHydration}>
                                Chat Not Working?
                            </MDButton>
                        )
                    }
                </Grid>
            </Box>
        </>
    );
};

export default ChatHeaderMobile;
